import Loadable from 'react-loadable';
import { Loading } from '../components';

export const ScreenLayout = Loadable({
  loader: () => import('../screens/ScreenLayout/ScreenLayout'),
  loading: Loading,
});

export const MixScreen = Loadable({
  loader: () => import('../screens/MixScreen'),
  loading: Loading,
});
export const BlankPage = Loadable({
  loader: () => import('../screens/blankPage/blankPage'),
  loading: Loading,
});
export const FormCantrol = Loadable({
  loader: () => import('../components/FormCantrol/FormCantrol'),
  loading: Loading,
});

export const Login = Loadable({
  loader: () => import('../screens/Login/Login'),
  loading: Loading,
});

export const Setpassword = Loadable({
  loader: () => import('../screens/setPassword/setPassword'),
  loading: Loading,
});


export const PrivacyPolicy = Loadable({
  loader: () => import('../screens/PrivacyPolicy/PrivacyPolicy'),
  loading: Loading,
});

export const HTMLEmailDemo = Loadable({
  loader: () => import('../screens/HTMLEmailDemo/HTMLEmailDemo'),
  loading: Loading,
});


// Admin Screen


export const UserLoginLogs = Loadable({
  loader: () => import('../screens/AdminScreen/UserLoginLogs/UserLoginLogs'),
  loading: Loading,
});
export const BugTracker = Loadable({
  loader: () => import('../screens/AdminScreen/BugTracker/BugTracker'),
  loading: Loading,
});

//ACS
export const Rights = Loadable({
  loader: () => import('../screens/AdminScreen/ACS/Rights/Rights'),
  loading: Loading,
});
export const Roles = Loadable({
  loader: () => import('../screens/AdminScreen/ACS/Roles/Roles'),
  loading: Loading,
});

export const Screens = Loadable({
  loader: () => import('../screens/AdminScreen/ACS/Screens/Screens'),
  loading: Loading,
});



// Communication
export const CommunicationTemplates = Loadable({
  loader: () => import('../screens/AdminScreen/Communication/CommunicationTemplates/CommunicationTemplates'),
  loading: Loading,
});
export const CommunicationConfiguration = Loadable({
  loader: () => import('../screens/AdminScreen/Communication/CommunicationConfiguration/CommunicationConfiguration'),
  loading: Loading,
});
export const Communications = Loadable({
  loader: () => import('../screens/AdminScreen/Communication/Communications/Communications'),
  loading: Loading,
});
export const Notification = Loadable({
  loader: () => import('../screens/AdminScreen/Notification/Notification'),
  loading: Loading,
});

// Tenant
export const TenantDomains = Loadable({
  loader: () => import('../screens/AdminScreen/Tenant/TenantDomains/TenantDomains'),
  loading: Loading,
});
export const Tenants = Loadable({
  loader: () => import('../screens/AdminScreen/Tenant/Tenants/Tenants'),
  loading: Loading,
});
export const TenantSMTP = Loadable({
  loader: () => import('../screens/AdminScreen/Tenant/TenantSMTP/TenantSMTP'),
  loading: Loading,
});
export const SiteConfigurations = Loadable({
  loader: () => import('../screens/AdminScreen/Tenant/SiteConfigurations/SiteConfigurations'),
  loading: Loading,
});


//Materials
export const Materials = Loadable({
  loader: () => import('../screens/AdminScreen/Materials/Materials/Materials'),
  loading: Loading,
});
export const MaterialInspection = Loadable({
  loader: () => import('../screens/AdminScreen/Materials/MaterialInspection/MaterialInspection'),
  loading: Loading,
});
export const MaterialPriceChangeHistory = Loadable({
  loader: () => import('../screens/AdminScreen/Materials/MaterialPriceChangeHistory/MaterialPriceChangeHistory'),
  loading: Loading,
});
export const MaterialTestType = Loadable({
  loader: () => import('../screens/AdminScreen/MaterialTestType/MaterialTestType'),
  loading: Loading,
});


export const ApiLogs = Loadable({
  loader: () => import('../screens/AdminScreen/APILogs/APILogs'),
  loading: Loading,
});
export const Documents = Loadable({
  loader: () => import('../screens/AdminScreen/Documents/Document'),
  loading: Loading,
});
export const DocumentTypes = Loadable({
  loader: () => import('../screens/AdminScreen/DocumentTypes/DocumentTypes'),
  loading: Loading,
});
export const MaterialTypeTestTypes = Loadable({
  loader: () => import('../screens/AdminScreen/Master/MaterialTypeTestTypes/MaterialTypeTestTypes'),
  loading: Loading,
});
export const Locations = Loadable({
  loader: () => import('../screens/AdminScreen/Master/Locations/Locations'),
  loading: Loading,
});
export const MaterialPriceUnit = Loadable({
  loader: () => import('../screens/AdminScreen/Master/MaterialPriceUnit/MaterialPriceUnit'),
  loading: Loading,
});
export const MaterialType = Loadable({
  loader: () => import('../screens/AdminScreen/Master/MaterialType/MaterialType'),
  loading: Loading,
});
export const TestTypes = Loadable({
  loader: () => import('../screens/AdminScreen/Master/TestTypes/TestTypes'),
  loading: Loading,
});
export const Moldings = Loadable({
  loader: () => import('../screens/AdminScreen/Master/Moldings/Moldings'),
  loading: Loading,
});
export const PolymerTypes = Loadable({
  loader: () => import('../screens/AdminScreen/Master/PolymerTypes/PolymerTypes'),
  loading: Loading,
});
export const Properties = Loadable({
  loader: () => import('../screens/AdminScreen/Master/Properties/Properties'),
  loading: Loading,
});
export const BatchType = Loadable({
  loader: () => import('../screens/AdminScreen/Master/BatchType/BatchType'),
  loading: Loading,
});
export const Manufacturers = Loadable({
  loader: () => import('../screens/AdminScreen/Master/Manufacturers/Manufacturers'),
  loading: Loading,
});
export const Users = Loadable({
  loader: () => import('../screens/AdminScreen/Users/Users'),
  loading: Loading,
});

//Master

// Compounds
export const Compounds = Loadable({
  loader: () => import('../screens/AdminScreen/Compounds/Compounds'),
  loading: Loading,
});
export const CompoundMaterials = Loadable({
  loader: () => import('../screens/AdminScreen/CompoundMaterials/CompoundMaterials'),
  loading: Loading,
});
export const CompoundProperties = Loadable({
  loader: () => import('../screens/AdminScreen/CompoundProperties/CompoundProperties'),
  loading: Loading,
});
export const CompoundMixingInstructions = Loadable({
  loader: () => import('../screens/AdminScreen/CompoundMixingInstructions/CompoundMixingInstructions'),
  loading: Loading,
});
export const CompoundMoldings = Loadable({
  loader: () => import('../screens/AdminScreen/CompoundMoldings/CompoundMoldings'),
  loading: Loading,
});
export const Batches = Loadable({
  loader: () => import('../screens/AdminScreen/Batches/Batches'),
  loading: Loading,
});
export const CompareCompound = Loadable({
  loader: () => import('../screens/AdminScreen/CompareCompound/CompareCompound'),
  loading: Loading,
});






export const MainPage = Loadable({
  loader: () => import('../screens/MainPage/MainPage'),
  loading: Loading,
});
