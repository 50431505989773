//Admin Side Start
export class clsGetNav {
	constructor() {

	}
}

// ChangePassword
export class clsChangePassword {
	constructor() {
		this.ID = null;
		this.Password = null;
		this.NewPassword = null;
	}
}

// Forgot Password
export class clsForgotPassword {
	constructor() {
		this.EmailAddress = null;
	}
}

//User 
//Usres

export class clsGetUsers {
	constructor() {

		this.FirstName = null;
		this.LastName = null;
		this.Username = null;
		this.EmailAddress = null;
		this.MobileNo = null;
		this.RoleNo = null;
		this.IsActive = null;
		this.LastSyncAt = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null

	}
}

export class clsGetUserByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteUser {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditUser {
	constructor() {
		this.ID = null;
		this.FirstName = null;
		this.LastName = null;
		this.Username = null;
		this.Password = null;
		this.EmailAddress = null;
		this.MobileNo = null;
		this.RoleID = null;
		this.IsActive = null;
	}
}

export class clsGetUserForDropdown {
	constructor() {
		this.RoleNo = null;
	}
}


//GetBugTracker

export class clsGetBugTracker {
	constructor() {
		this.IsResolved = null;
		this.BugNo = null;
		this.InnerException = null;
		this.IsNewBug = null;
		this.UserName = null;
		this.ErrorOccurredAt = null;
		this.Description = null;
		this.PageURL = null;
		this.CurrentPage = null;
		this.RecordPerPage = null;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsResolveBug {
	constructor() {
		this.ID = null;
		this.ResolutionDetails = null;

	}
}

export class clsGetBuyByID {
	constructor() {
		this.ID = null;


	}
}



//Acs

//Rights
export class clsGetRights {
	constructor() {
		this.Name = null;
		this.RightNo = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;

	}
}
export class clsGetRightForDropdown {
	constructor() {

	}
}
export class clsAddEditRight {
	constructor() {
		this.ID = null;
		this.RightNo = null;
		this.Name = null;
		this.IsActive = null;
	}
}
export class clsDeleteRight {
	constructor() {
		this.ID = null;
	}
}
export class clsGetRightByID {
	constructor() {
		this.ID = null;
	}
}


//Roles
export class clsGetRoles {
	constructor() {
		this.Name = null;
		this.RoleNo = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;

	}
}
export class clsGetRoleForDropdown {
	constructor() {

	}
}
export class clsAddEditRole {
	constructor() {
		this.ID = null;
		this.RoleNo = null;
		this.Name = null;
		this.IsActive = null;
		this.JSON = null;
	}
}
export class clsDeleteRole {
	constructor() {
		this.ID = null;
	}
}
export class clsGetRoleByID {
	constructor() {
		this.ID = null;
	}
}

//Screens
export class clsGetScreens {
	constructor() {
		this.ParentName = null;
		this.MenuPath = null;
		this.IsParent = null;
		this.IsTitle = null;
		this.IsActive = null;
		this.SequenceNo = null;
		this.Name = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;

	}
}
export class clsGetScreenForDropdown {
	constructor() {

	}
}
export class clsAddEditScreen {
	constructor() {
		this.ID = null;
		this.IsTitle = null;
		this.ParentID = null;
		this.IsActive = null;
		this.MenuPath = null;
		this.IsParent = null;
		this.SequenceNo = null;
		this.Name = null;
		this.JSON = null;
	}
}
export class clsDeleteScreen {
	constructor() {
		this.ID = null;
	}
}
export class clsGetScreenByID {
	constructor() {
		this.ID = null;
	}
}
export class clsGetScreenForDropdownIsParent {
	constructor() {
		this.IsParent = null;
	}
}

// communication
// communications
export class clsGetCommunications {
	constructor() {

		this.EmailSubject = null;
		this.ContactName = null;
		this.EmailAddress = null;
		this.TypeName = null;
		this.NotificationAt = null;
		this.IsRead = null;
		this.ReadAt = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;


	}
}
export class clsSetCommunicationTemplate {
	constructor() {

		this.ID = null;
		this.HTML = null;

	}
}

//CommunicationConfiguration

export class clsAddEditCommunicationConfiguration {
	constructor() {
		this.ID = null;
		this.CommunicationTemplateID = null;
		this.UserIDs = null;
		this.RoleIDs = null;
		this.SendToSelf = null;
		this.SendSMS = null;
		this.SendEmail = null;
		this.SendBrowserNotification = null;
		this.SendAppNotification = null;
	}
}
export class clsDeleteCommunicationConfiguration {
	constructor() {
		this.ID = null;

	}
}
export class clsGetCommunicationConfigurationByID {
	constructor() {
		this.ID = null;
	}
}
export class clsGetCommunicationConfigurations {
	constructor() {
		this.CommunicationTemplateName = null;
		this.UserName = null;
		this.RoleName = null;
		this.SendToSelf = null;
		this.SendEmail = null;
		this.SendBrowserNotification = null;
		this.SendAppNotification = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsGetCommunicationConfigurationForDropdown {
	constructor() {

	}
}

//CommunicationTemplates
export class clsDownloadCommunicationTemplate {
	constructor() {
		this.ID = null;
	}
}

export class clsGetCommunicationTemplates {
	constructor() {
		this.TemplateName = null;
		this.EmailSubject = null;
		this.TypeID = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsAddEditCommunicationTemplate {
	constructor() {
		this.IsActive = null;
		this.EmailSubject = null;
		this.BrowserNotyTitle = null;
		this.ID = null;
		this.InAppTitle = null;
		this.SMSText = null;
		this.TypeModule = null;
		this.TemplateName = null;
		this.FriendlyTitle = null;
		this.PushNotyTitle = null;
		this.BrowserNotyName = null;
		this.EmailBody = null;
		this.TypeID = null;
		this.InAppNoty = null;
		this.PushNotyName = null
	}
}

export class clsDeleteCommunicationTemplate {
	constructor() {

		this.ID = null;
	}
}

export class clsGetCommunicationTemplateByID {
	constructor() {
		this.ID = null;
	}
}
export class clsGetCommunicationTemplateForDropdown {
	constructor() {
		this.IsConfigurableByAdmin = null;
	}
}


// Tenant

//TenantSMTP
export class clsGetTenantSMTPForDropdown {

}

export class clsGetTenantSMTPByID {
	constructor() {
		this.ID = null;
	}
}

export class clsGetTenantSMTP {
	constructor() {
		this.Host = null;
		this.Port = null;
		this.IsSSL = null;
		this.UserName = null;
		this.EmailFrom = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsDeleteTenantSMTP {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditTenantSMTP {
	constructor() {
		this.ID = null;
		this.Host = null;
		this.Port = null;
		this.IsSSL = null;
		this.UserName = null;
		this.Password = null;
		this.EmailFrom = null;
	}
}

//Tenant

export class clsGetTenants {
	constructor() {
		this.Name = null;
		this.CompanyName = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsAddEditTenant {
	constructor() {
		this.Name = null;
		this.CompanyName = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsDeleteTenant {
	constructor() {
		this.ID = null;

	}
}
export class clsGetTenantByID {
	constructor() {
		this.ID = null;

	}
}

//TenantDomain

export class clsAddEditTenantDomain {
	constructor() {
		this.ID = null;
		this.URL = null;
	}
}

export class clsDeleteTenantDomain {
	constructor() {
		this.ID = null;
	}
}

export class clsGetTenantDomainByID {
	constructor() {
		this.ID = null;
	}
}

export class clsGetTenantDomains {
	constructor() {
		this.URL = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;

	}
}
export class clsGetTenantDomainForDropdown {
	constructor() {
		this.URL = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;

	}
}

// Site Configuration
export class clsAddEditTenantConfiguration {
	constructor() {
		this.ID = null;
		this.PageTitle = null;
		this.LoginHeaderWelcomeText = null;
		this.LoginTitle1 = null;
		this.LoginTitle2 = null;
		this.LoginCopyrightText = null;
		this.HeaderWelcomeText = null;
		this.Title1 = null;
		this.Title2 = null;
		this.CopyrightText = null;
		this.AllLocationDescriptionText = null;
		this.AllLocationInstructionText = null;
		this.ShowRegister = null;
		this.ShowHomeIcon = null;
		this.Theme = null;
		this.RegistrationTitle = null;
		this.RegistrationSubTitle = null;
		this.RegistrationMessage = null;
	}
}

export class clsGetTenantConfigurationByID {
	constructor() {
		this.ID = null;
	}
}
export class clsGetTenantConfiguration {
	constructor() {

	}
}

// APILogs
export class clsGetAPILogs {
	constructor() {
		this.MethodName = null;
		this.RequestAt = null;
		this.ResponseAt = null;
		this.Status = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;

	}
}

//Document
export class clsGetDocuments {
	constructor() {
		this.ModuleName = null;
		this.CreatedByName = null;
		this.SizeinKB = null;
		this.DocType = null;
		this.Description = null;
		this.LastModifiedAt = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsGetDocumentsForDropdown {

}
export class clsGetDocumentForDropdown {

}

export class clsGetDocumentsByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteDocument {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditDocument {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.SequenceNo = null;
		this.IsActive = null;
		this.CreatedByID = null;
	}
}





// //Tenant

// export class clsGetTenants {
// 	constructor() {
// 		this.Name = null;
// 		this.CompanyName = null;
// 		this.IsActive = null;
// 		this.CurrentPage = 1;
// 		this.RecordPerPage = 25;
// 		this.SortExpression = null;
// 		this.SortDirection = null;
// 	}
// }

// export class clsAddEditTenant {
// 	constructor() {
// 		this.Name = null;
// 		this.CompanyName = null;
// 		this.IsActive = null;
// 		this.CurrentPage = 1;
// 		this.RecordPerPage = 25;
// 		this.SortExpression = null;
// 		this.SortDirection = null;
// 	}
// }
// export class clsDeleteTenant {
// 	constructor() {
// 		this.ID = null;

// 	}
// }
// export class clsGetTenantByID {
// 	constructor() {
// 		this.ID = null;

// 	}
// }


// //TenantSMTP
// export class clsGetTenantSMTPForDropdown {

// }

// export class clsGetTenantSMTPByID {
// 	constructor() {
// 		this.ID = null;
// 	}
// }

// export class clsGetTenantSMTP {
// 	constructor() {
// 		this.Host = null;
// 		this.Port = null;
// 		this.IsSSL = null;
// 		this.UserName = null;
// 		this.EmailFrom = null;
// 		this.CurrentPage = 1;
// 		this.RecordPerPage = 25;
// 		this.SortExpression = null;
// 		this.SortDirection = null;
// 	}
// }

// export class clsDeleteTenantSMTP {
// 	constructor() {
// 		this.ID = null;
// 	}
// }

// export class clsAddEditTenantSMTP {
// 	constructor() {
// 		this.ID = null;
// 		this.Host = null;
// 		this.Password = null;
// 		this.Port = null;
// 		this.IsSSL = null;
// 		this.UserName = null;
// 		this.Password = null;
// 		this.EmailFrom = null;
// 	}
// }


// //TenantDomain

// export class clsAddEditTenantDomain {
// 	constructor() {
// 		this.ID = null;
// 		this.URL = null;
// 	}
// }

// export class clsDeleteTenantDomain {
// 	constructor() {
// 		this.ID = null;
// 	}
// }

// export class clsGetTenantDomainByID {
// 	constructor() {
// 		this.ID = null;
// 	}
// }

// export class clsGetTenantDomains {
// 	constructor() {
// 		this.URL = null;
// 		this.CurrentPage = 1;
// 		this.RecordPerPage = 25;
// 		this.SortExpression = null;
// 		this.SortDirection = null;

// 	}
// }
// export class clsGetTenantDomainForDropdown {
// 	constructor() {
// 		this.URL = null;
// 		this.CurrentPage = 1;
// 		this.RecordPerPage = 25;
// 		this.SortExpression = null;
// 		this.SortDirection = null;

// 	}
// }



// //Communications

// //Communications

// export class clsGetCommunications {
// 	constructor() {

// 		this.EmailSubject = null;
// 		this.ContactName = null;
// 		this.EmailAddress = null;
// 		this.Type = null;
// 		this.NotificationAt = null;
// 		this.IsRead = null;
// 		this.ReadAt = null;
// 		this.CurrentPage = 1;
// 		this.RecordPerPage = 25;
// 		this.SortExpression = null;
// 		this.SortDirection = null;


// 	}
// }

// //CommunicationTemplate

// export class clsDownloadCommunicationTemplate {
// 	constructor() {
// 		this.ID = null;
// 	}
// }
// export class clsAddEditCommunicationConfiguration {
// 	constructor() {
// 		this.ID = null;
// 		this.CommunicationTemplateID = null;
// 		this.TradeTeamID = null;
// 		this.UserLevelIDs = null;
// 		this.DesignationIDs = null;
// 		this.UserIDs = null;
// 		this.RoleIDs = null;
// 		this.TradeTeamIDs = null;
// 		this.SendToSelf = null;
// 		this.SendToHOD = null;
// 		this.SendSMS = null;
// 		this.SendEmail = null;
// 		this.SendBrowserNotification = null;
// 		this.SendAppNotification = null;
// 	}
// }
// export class clsDeleteCommunicationConfiguration {
// 	constructor() {
// 		this.ID = null;

// 	}
// }
// export class clsGetCommunicationConfigurationByID {
// 	constructor() {
// 		this.ID = null;
// 	}
// }
// export class clsGetCommunicationConfigurations {
// 	constructor() {
// 		this.CommunicationTemplateName = null;
// 		this.TradeTeamName = null;
// 		this.UserLevelName = null;
// 		this.DesignationName = null;
// 		this.UserName = null;
// 		this.RoleName = null;
// 		this.TradeTeamNames = null;
// 		this.SendToSelf = null;
// 		this.SendToHOD = null;
// 		this.SendSMS = null;
// 		this.SendEmail = null;
// 		this.SendBrowserNotification = null;
// 		this.SendAppNotification = null;
// 		this.CurrentPage = 1;
// 		this.RecordPerPage = 25;
// 		this.SortExpression = null;
// 		this.SortDirection = null;
// 	}
// }
// export class clsGetCommunicationConfigurationForDropdown {
// 	constructor() {

// 	}
// }


// //CommunicationTemplates

// export class clsGetCommunicationTemplates {
// 	constructor() {
// 		this.TemplateName = null;
// 		this.EmailSubject = null;
// 		this.TypeID = null;
// 		this.IsActive = null;
// 		this.CurrentPage = 1;
// 		this.RecordPerPage = 25;
// 		this.SortExpression = null;
// 		this.SortDirection = null;
// 	}
// }

// export class clsAddEditCommunicationTemplate {
// 	constructor() {
// 		this.IsActive = null;
// 		this.EmailSubject = null;
// 		this.BrowserNotyTitle = null;
// 		this.ID = null;
// 		this.InAppTitle = null;
// 		this.SMSText = null;
// 		this.TypeModule = null;
// 		this.TemplateName = null;
// 		this.FriendlyTitle = null;
// 		this.PushNotyTitle = null;
// 		this.BrowserNotyName = null;
// 		this.EmailBody = null;
// 		this.TypeID = null;
// 		this.InAppNoty = null;
// 		this.PushNotyName = null
// 	}
// }

// export class clsDeleteCommunicationTemplate {
// 	constructor() {

// 		this.ID = null;
// 	}
// }

// export class clsGetCommunicationTemplateByID {
// 	constructor() {
// 		this.ID = null;
// 	}
// }
// export class clsGetCommunicationTemplateForDropdown {
// 	constructor() {
// 		this.IsConfigurableByAdmin = null;
// 	}
// }



// Master

//MaterialPriceChangeHistory
export class clsGetMaterialPriceChangeHistory {
	constructor() {
		this.ID = null;
		this.FromPrice = null;
		this.ToPrice = null;
		this.ChangedAt = null;
		this.ChangedByName = null;
		this.MaterialName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}


//MaterialTypeTestTypes
export class clsGetMaterialTypeTestTypes {
	constructor() {
		this.SequenceNo = null;
		this.MaterialTypeID = null;
		this.TestTypeName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetMaterialTypeTestTypeForDropdown {
	constructor() {
		this.MaterialTypeID = null;
	}
}

export class clsGetMaterialTypeTestTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteMaterialTypeTestType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditMaterialTypeTestType {
	constructor() {
		this.ID = null;
		this.MaterialTypeID = null;
		this.TestTypeID = null;
		this.SequenceNo = null;
	}
}


//MaterialTypes
export class clsGetMaterialTypes {
	constructor() {
		this.MaterialID = null;
		this.Name = null;
		this.Description = null;
		this.ShortCode = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsSetPassword {
	constructor() {
		this.Password = null;
		this.RequestID = null;
	}
}

export class clsResetPassword {
	constructor() {
		this.MobileNo = null;
	}
}

export class clsGetMyProfile {
	constructor() {
		this.ID = null;
	}
}
export class clsEditProfile {
	constructor() {
		this.ID = null;
		this.FirstName = null;
		this.LastName = null;
		this.EmailAddress = null;
		this.MobileNo = null;
	}
}

export class clsGetMaterialTypeForDropdown {

}

export class clsGetMaterialTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteMaterialType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditMaterialType {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.ShortCode = null;
		this.IsActive = null;
	}
}
// Properties

export class clsGetProperties {
	constructor() {
		this.Name = null;
		this.Description = null;
	}
}
export class clsGetPropertyForDropdown {

}

export class clsGetPropertyByID {
}

export class clsDeleteProperty {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditProperty {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
	}
}

// BatchType

export class clsGetBatchTypes {
	constructor() {
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetBatchTypeForDropdown {

}

export class clsGetBatchTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteBatchType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditBatchType {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
	}
}

// Manufacturers
export class clsGetManufacturers {
	constructor() {
		this.Name = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetManufacturerForDropdown {

}

export class clsGetManufacturerByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteManufacturer {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditManufacturer {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.IsActive = null;
	}
}

// TestType
export class clsGetTestTypes {
	constructor() {
		this.Name = null;
		this.Description = null;
		this.UpperLimit = null;
		this.LowerLimit = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsGetTestTypeForDropdown {

}

export class clsGetTestTypeByID {
	constructor() {
		this.ID = null;
	}
}
export class clsDeleteTestType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditTestType {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.UpperLimit = null;
		this.LowerLimit = null;
		this.IsActive = null;
	}
}

// Moldings
export class clsGetMoldings {
	constructor() {
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetMoldingForDropdown {

}

export class clsGetMoldingByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteMolding {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditMolding {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
	}
}

// PolymerTypes
export class clsGetPolymerTypes {
	constructor() {
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetPolymerTypeForDropdown {

}

export class clsGetPolymerTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeletePolymerType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditPolymerType {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
	}
}


//DocumentTypes
export class clsGetDocumentTypes {
	constructor() {
		this.Name = null;
		this.SequenceNo = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsGetDocumentTypeForDropdown {

}

export class clsGetDocumentTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteDocumentType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditDocumentType {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.SequenceNo = null;
		this.IsActive = null;
		this.CreatedByID = null;
	}
}

//Materials

export class clsGetMaterials {
	constructor() {
		this.Name = null;
		this.Code = null;
		this.SPG = null;
		this.Price = null;
		this.MaterialPriceUnitName = null;
		this.SDSLink = null;
		this.TDSLink = null;
		this.IsActive = null;
		this.ManufacturerName = null;
		this.MaterialTypeName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}


export class clsGetMaterialForDropdown {

}

export class clsGetMaterialByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteMaterial {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditMaterial {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.Code = null;
		this.MaterialTypeID = null;
		this.MaterialPriceUnitID = null;
		this.SPG = null;
		this.Price = null;
		this.ManufacturerID = null;
		this.SDSLink = null;
		this.TDSLink = null;
		this.Comments = null;
		this.IsActive = null;
	}
}

// Material Test Type

export class clsGetMaterialTestTypes {
	constructor() {
		this.SequenceNo = null;
		this.TestTypeName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetMaterialTestTypeForDropdown {
	constructor() {
		this.ID = null;
	}
}

export class clsGetMaterialTestTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteMaterialTestType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditMaterialTestType {
	constructor() {
		this.ID = null;
		this.MaterialID = null;
		this.TestTypeID = null;
		this.SequenceNo = null;
	}
}

//MaterialInspection

export class clsAddEditMaterialInspection {
	constructor() {
		this.ID = null;
		this.MaterialID = null;
		this.PONo = null;
		this.ReceivedDate = null;
		this.LocationID = null;
		this.ManufacturerID = null;
		this.Remarks = null;
		this.JSON = null;
	}
}

export class clsDeleteMaterialInspection {
	constructor() {
		this.ID = null;
	}
}

export class clsGetMaterialInspection {
	constructor() {
		this.MaterialID = null;
		this.Result = null;
		this.TestedAt = null;
		this.TestedByName = null;
		this.MaterialName = null;
		this.PONo = null;
		this.ReceivedDate = null;
		this.LocationName = null;
		this.ManufacturerName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetMaterialInspectionByID {
	constructor() {
		this.ID = null;
	}
}


// Compounds
// Compounds
export class clsGetCompounds {
	constructor() {
		this.Name = null;
		this.Revision = null;
		this.Specifications = null;
		this.Duro = null;
		this.BatchFactor = null;
		this.BatchSize = null;
		this.LastModifiedAt = null;
		this.LastModifiedByName = null;
		this.PolymerTypeName = null;
		this.IsActive = null;
		// this.Status = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetCompoundByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteCompound {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditCompound {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Revision = null;
		this.PolymerTypeID = null;
		this.Specifications = null;
		this.Duro = null;
		// this.DuroVariation = null;
		this.Comments = null;
		this.BatchFactor = null;
		this.BatchSize = null;
	}
}

// CompoundMaterials
export class clsGetCompoundMaterials {
	constructor() {
		this.PPHR = null;
		this.Grams = null;
		this.SPG = null;
		this.Code = null;
		this.LastModifiedAt = null;
		this.LastModifiedByName = null;
		this.MaterialName = null;
		this.CompoundName = null;
		this.CompoundID = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetCompoundMaterialByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteCompoundMaterial {
	constructor() {
		this.ID = null;
		this.CompoundID = null;
	}
}

export class clsAddEditCompoundMaterial {
	constructor() {
		this.ID = null;
		this.CompoundID = null;
		this.MaterialID = null;
		this.PPHR = null;
		this.Grams = null;
		this.SPG = null;
	}
}

// CompoundProperties
export class clsGetCompoundProperties {
	constructor() {
		this.SequenceNo = null;
		this.Details = null;
		this.LastModifiedAt = null;
		this.PropertyName = null;
		this.CompoundName = null;
		this.LastModifiedByName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}


export class clsGetCompoundPropertyByID {
	constructor() {
		this.ID = null;
	}
}
export class clsDeleteCompoundProperty {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditCompoundProperty {
	constructor() {
		this.ID = null;
		this.CompoundID = null;
		this.PropertyID = null;
		this.SequenceNo = null;
		this.Details = null;
	}
}
// CompoundMixingInstructions
export class clsGetCompoundMixingInstructions {
	constructor() {
		this.Instructions = null;
		this.LastModifiedAt = null;
		this.LastModifiedByName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetCompoundMixingInstructionByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteCompoundMixingInstruction {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditCompoundMixingInstruction {
	constructor() {
		this.ID = null;
		this.CompoundID = null;
		this.Instructions = null;
	}
}

// Compound Moldings
export class clsGetCompoundMoldings {
	constructor() {
		this.IsSelected = null;
		this.Time = null;
		this.Temperature = null;
		this.LastModifiedAt = null;
		this.MoldingName = null;
		this.CompoundName = null;
		this.LastModifiedByName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetCompoundMoldingByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteCompoundMolding {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditCompoundMolding {
	constructor() {
		this.ID = null;
		this.CompoundID = null;
		this.MoldingID = null;
		this.IsSelected = null;
		this.Time = null;
		this.Temperature = null;
	}
}

// CreateNewRevision
export class clsCreateNewRevision {
	constructor() {
		this.ID = null;
		this.Name = null;

	}
}

// Locations

export class clsGetLocations {
	constructor() {
		this.Name = null;
		this.Description = null;
		this.SequenceNo = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}


export class clsGetLocationByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteLocation {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditLocation {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.SequenceNo = null;
		this.IsActive = null;
	}
}
export class clsGetLocationForDropdown {
	constructor() {
	}
}

// MaterialPriceUnits

export class clsGetMaterialPriceUnits {
	constructor() {
		this.Name = null;
		this.ShortName = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsGetMaterialPriceUnitByID {
	constructor() {
		this.ID = null;
	}
}
export class clsGetMaterialPriceUnitForDropdown {
	constructor() {
	}
}
export class clsDeleteMaterialPriceUnit {
	constructor() {
		this.ID = null;
	}
}
export class clsAddEditMaterialPriceUnit {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.ShortName = null;
		this.IsActive = null;
	}
}

// Batches

export class clsGetBatches {
	constructor() {
		this.BatchNo = null;
		this.BatchSize = null;
		this.CalculatedBatchSize = null;
		this.LastModifiedAt = null;
		this.Revision = null;
		this.LastModifiedByName = null;
		this.CompoundName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetBatcheForDropdown {
	constructor() {
		this.CompoundID = null;
	}
}

export class clsGetBatchByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteBatch {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditBatche {
	constructor() {
		this.ID = null;
		this.CompoundID = null;
		this.BatchSize = null;
	}
}
export class clsAddEditBatch {
	constructor() {
		this.ID = null;
		this.CompoundID = null;
		this.BatchSize = null;
	}
}
export class clsGetCompoundsForDropdown {
	constructor() {
		this.Name = null;
	}
}
export class clsCalculateBatchSize {
	constructor() {
		this.BatchSize = null;
		this.CompoundID = null;

	}
}

