import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import {
  ScreenLayout, MixScreen, BlankPage, FormCantrol, Login, Setpassword, PrivacyPolicy, HTMLEmailDemo,

  BugTracker, Rights, Roles, Screens, Users, MaterialType, TestTypes, Moldings, PolymerTypes, Properties, BatchType, Manufacturers,
  CommunicationTemplates, CommunicationConfiguration, Communications, TenantDomains, Tenants, TenantSMTP, SiteConfigurations,
  ApiLogs, Documents, DocumentTypes, Materials, MaterialInspection, MaterialPriceChangeHistory,MaterialTestType, MaterialTypeTestTypes, Compounds,
  CompoundMaterials, CompoundProperties, CompoundMixingInstructions, CompoundMoldings,Notification,
  Locations,MaterialPriceUnit,Batches,CompareCompound,
  MainPage,


} from "./source/config/PagePathList";
import { useEffect, useState } from 'react';
import { BaseColor, BaseImages, FontFamily } from './source/config';
import { IsEmpty, addItemStore, authService, storageKeys } from './source/config/appConstant';
import { clsGetTenantConfiguration } from './source/API/APIParameters';
import * as APIController from './source/API/APIController';
import { CommonLoader } from './source/components';

function App() {
  const [loading, setloading] = useState(true);
  const isLoggedIn = authService.checkLogin();

  useEffect(() => {
    // Update CSS variables with JavaScript  // For Global Colors And Images Call From Api as well as appconfig file

    Object.entries(BaseImages).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
    Object.entries(FontFamily).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
    Object.entries(BaseColor).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn === false) {
      WsGetTenantConfiguration();
    }
  }, [isLoggedIn]);
  const WsGetTenantConfiguration = (response) => {
    setloading(true)
    const objParameter = new clsGetTenantConfiguration();
    APIController.GetTenantConfiguration(objParameter)
      .then((response) => {
        if (response.error == null) {
          if (!IsEmpty(response.data)) {
            localStorage.setItem('pageTitle', response.data[0].a);
            localStorage.setItem('faviconUrl', response.data[0].l);
            addItemStore(storageKeys.TenantConfiguration, JSON.stringify(response.data))
            setloading(false)
          }

        }
      })
  }


  if (isLoggedIn) {
    return (
      <div>
        <ScreenLayout>
          <Routes>
            <Route path="/mixScreen" element={<MixScreen />} />
            <Route path="/blankpage" element={<BlankPage />} />
            <Route path="/formcantrol" element={<FormCantrol />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/HTMLEmailDemo" element={<HTMLEmailDemo />} />
            <Route path="/notification" element={<Notification />} />

            {/* Users  */}
            <Route path="/users" element={<Users />} />

            {/* /ACS  */}
            <Route path="/acs/rights" element={<Rights />} />
            <Route path="/acs/roles" element={<Roles />} />
            <Route path="/acs/screens" element={<Screens />} />

            {/* Bug Tracker */}
            <Route path="/bugtracker/bugtracker" element={<BugTracker />} />

            {/* Communication */}
            <Route path="/Communication/communicationtemplates" element={<CommunicationTemplates />} />
            <Route path="/Communication/CommunicationConfiguration" element={<CommunicationConfiguration />} />
            <Route path="/Communication/Communications" element={<Communications />} />

            {/* Tenant */}
            <Route path="/tetant/tenantdomains" element={<TenantDomains />} />
            <Route path="/tetant/tenants" element={<Tenants />} />
            <Route path="/tetant/tenantsmtp" element={<TenantSMTP />} />
            <Route path="/tetant/siteconfigurations" element={<SiteConfigurations />} />
            <Route path="/tetant/ApiLogs" element={<ApiLogs />} />
            <Route path="/tetant/Documents" element={<Documents />} />
            <Route path="/tetant/DocumentTypes" element={<DocumentTypes />} />


            {/* Material */}
            <Route path="/materials" element={<Materials />} />
            <Route path="/material/inspection" element={<MaterialInspection />} />
            <Route path="/material/priceChangehistory" element={<MaterialPriceChangeHistory />} />
            <Route path="/material/materialtesttype" element={<MaterialTestType />} />

            {/* Master */}
            <Route path="/master/materialtype" element={<MaterialType />} />
            <Route path="/master/materialtypetesttypes" element={<MaterialTypeTestTypes />} />
            <Route path="/master/testtypes" element={<TestTypes />} />
            <Route path="/master/polymertypes" element={<PolymerTypes />} />
            <Route path="/master/moldings" element={<Moldings />} />
            <Route path="/master/properties" element={<Properties />} />
            <Route path="/master/batchtype" element={<BatchType />} />
            <Route path="/master/manufacturers" element={<Manufacturers />} />
            <Route path="/master/Locations" element={<Locations />} />
            <Route path="/master/MaterialPriceUnit" element={<MaterialPriceUnit />} />

            <Route path="*" element={<Navigate replace to="/homescreen" />} />

            {/* Compounds */}
            <Route path="/compounds/compounds" element={<Compounds />} />
            <Route path="/compounds/Compare" element={<CompareCompound />} />
            <Route path="/compounds/CompoundMaterials" element={<CompoundMaterials />} />
            <Route path="/compounds/CompoundProperties" element={<CompoundProperties />} />
            <Route path="/compounds/CompoundMixingInstructions" element={<CompoundMixingInstructions />} />
            <Route path="/compounds/CompoundMoldings" element={<CompoundMoldings />} />

            {/* Batches */}
            <Route path="/Batches/Batches" element={<Batches />} />


            <Route path="/mainpage" element={<MainPage />} />

          </Routes>
        </ScreenLayout>
      </div>
    )
  } else {
    if (!loading) {
      return (
        <div>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/setPassword" element={<Setpassword />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        </div>
      );
    } else {
      return (
        <div>
          <CommonLoader />
        </div>

      )
    }
  }

}



export default App;
